import * as Yup from 'yup';

//this is the validation schema for pending orders.
const OrderSchema = Yup.object().shape({
  inCollections: Yup.number()
    .required('Required'),
  unpaidAssessmentsAmount: Yup.number()
    .min(0, 'No negative numbers!'),

  unpaidAssessmentsTypes: Yup.string(),

  associationLateFeeDue: Yup.number()
    .min(0, 'No negative numbers!'),

  managementLateFeeDue: Yup.number()
    .min(0, 'No negative numbers!'),

  otherFees: Yup.number()
    .min(0, 'No negative numbers!'),

  additionalText: Yup.string()
    .max(1000, "You are over the character limit.")

});

export default OrderSchema;