import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';

import userId from '../../userId';
import { IOrder, IOrdersData } from "../PendingOrdersPage/pendingOrdersInterfaces"
import generateDocTypeMap from "../../utils/generateDocTypeMap";
import getOrderDocLinks from "./getOrderDocLinks"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import { useHistory } from "react-router-dom";

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import formatPhoneNumber from '../../utils/formatPhoneNumber';

const CompletedOrdersPage = () => {

    const history = useHistory();
    //for getting around.

    const [ordersData, setOrdersData] = useState<IOrdersData>({ ordersArr: [], docTypeMap: undefined, orderDocLinks: [], loading: true });

    //retrieve client's orders from API
    const getCompletedOrders = async () => {


        return axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/completedOrders/${userId}`)
            .then(async function (response: AxiosResponse<any>) {
                // handle success

                const docTypeMap = await generateDocTypeMap(response.data.result);

                const docLinkArr = await getOrderDocLinks(response.data.result);

                // sort orders response.data.result by type
                let orderarr: Array<IOrder> = response.data.result.sort(
                    (order1: IOrder, order2: IOrder) => {
                        return order1.oidId - order2.oidId
                    }
                );



                setOrdersData({ ordersArr: orderarr, docTypeMap: docTypeMap, orderDocLinks: docLinkArr, loading: false });

            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
            }
            );
    };

    //download document with press of the button
    const downloadOrderDoc = (order: IOrder) => {

        axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/awss3Download/${order.oidId}`).then(response => {
            let link = document.createElement("a");
            link.href = response.data.result.getURL;
            link.download = `${order.ordersId}_doc.pdf`;
            link.click();


        })

    }

    //get pending orders when page renders
    useEffect(() => {
        getCompletedOrders();
    }, []);

    //show orders if retrieved by API
    if (!ordersData.loading) {

        return (
            <div style={{ marginTop: "2em" }}>
                <Row>
                    <Col className="justify-content-center">
                        <h1 className="page-title">Completed Orders</h1>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <Table striped responsive="xl" hover>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Doc Type</th>
                                    <th>HOA</th>
                                    <th>Address</th>
                                    <th>Buyer</th>
                                    <th>Files/Action</th>
                                    <th>
                                        <tr style={{ fontSize: "7px" }}><td className="purchased PFT">Purchased</td><td className="fulfill PFT">Fulfill By</td><td className="transfer PFT">Transfer</td></tr>
                                    </th>
                                </tr>
                            </thead>
                            {

                                ordersData.ordersArr.length > 0 ?

                                    ordersData.ordersArr.map(function (order: IOrder, index) {

                                        return (
                                            <tr key={order.oidId}>
                                                <td>{order.ordersId} </td>

                                                <td>{ordersData.docTypeMap.get(order.docTypeId)}</td>
                                                <td>{order.hoa.name}</td>
                                                <td>{order.address}</td>
                                                <td>
                                                    <tr>
                                                        {order.buyer.email}
                                                    </tr>
                                                    <tr>
                                                        {formatPhoneNumber(order.buyer.phone)}
                                                    </tr>
                                                </td>
                                                <td style={{ fontStyle: "italic" }}>
                                                    {/* <a href={ordersData.orderDocLinks[index]} on> */}
                                                    {
                                                        ordersData.orderDocLinks[index] ?
                                                            <Button size="sm" variant="info" onClick={((e: any) => downloadOrderDoc(order))}>Download Order Doc</Button> :
                                                            "File not available"
                                                    }
                                                    {/* Emergency button for sending order doc back to pending */}
                                                    {/* <Button size="sm" onClick={(e:any) => {
                                                        // make the axios call to set it back to pending
                                                        const orderItemDocumentUpdate = {
                                                            "item": {
                                                                "CompletedDate": null,
                                                                "DocumentStatusId": 2,
                                                                "CompletedBy": null,
                                                                "S3RelativePath": null 
                                                                }                    
                                                            }
                                        
                                                        axios.put(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/orderItemDocument/${order.oidId}`,
                                                            orderItemDocumentUpdate, {headers: {"Content-Type" : "application/json"}}
                                                        ).then((r : any) => {
                                                                console.log(r)
                                                                // refresh the page
                                                                window.location.reload();
                                                            }).catch( (error : any) => {
                                                            console.log(error)
                                                        })
                                                    }}> DEV: RESET TO PENDING</Button> */}
                                                    {/* </a> */}
                                                </td>
                                                <td>
                                                    <tr><td style={{ fontSize: "13px" }} className="purchased PFT">{new Date(order.purchaseDt).toLocaleString('en-US')} GMT</td></tr>
                                                    <tr><td style={{ fontSize: "13px" }} className="fulfill PFT">{new Date(order.dueDt).toLocaleString('en-US')} GMT</td></tr>
                                                    <tr><td style={{ fontSize: "13px" }} className="transfer PFT">{new Date(order.transferDt).toLocaleString('en-US')} GMT</td></tr>
                                                </td>
                                            </tr>
                                        )

                                    })

                                    :

                                    <tr>
                                        <td colSpan={7}>
                                            There are no pending orders at this time.
                                    </td>
                                    </tr>
                            }
                        </Table>
                    </Col>

                </Row>

            </div>
        )
    }
    else {
        return (
            <LoadingSpinner />
        )
    }
}

export default CompletedOrdersPage;