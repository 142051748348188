import Spinner from 'react-bootstrap/Spinner'

const LoadingSpinner = () => {

    const style = {
        height: "100vh",
        color: "purple",
        display: "flexbox",
        marginTop: "50%",
        marginLeft: "50%"

    }
    return (
        <div style={style}>
            <Spinner animation="border" />
        </div>


    )
}
export default LoadingSpinner