import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import formatPhoneNumber from '../../utils/formatPhoneNumber';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column'
    // fontFamily: "Calibri"
  },
  section: {
    margin: 5,
    padding: 5,
    fontSize: "14pt"

  },
  extraNotes: {
    height: "40%"
  },
  row: {
    flexDirection: "row",

  },
  spaceBetween: {
    justifyContent: "space-between",
    flexGrow: 1

  },
  header: {
    fontStyle: "bold",
    fontSize: "16pt",
    borderBottom: "2pt solid black"
  },
  title: {
    fontSize: "30pt",
    margin: 10,
    padding: 10,
    borderBottom: "1pt solid black",
    textAlign: "center"
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  plum: {
    backgroundColor: "#DDA0DD",
    color: "white",
    padding: "1pt"
  }
});

// Order Document Component
const OrderPdfTemplate = (props: any) => {

  let duesArr: Array<number> = [
    props.values.otherFees,
    props.values.unpaidAssessmentsAmount,
    props.values.associationLateFeeDue,
    props.values.managementLateFeeDue
  ];

  //sum total
  const dueSum: any = duesArr.reduce(function (a, b) {
    return a + b;
  }, 0);

  //turn numbers to currency
  const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Document >
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text >{props.docTypeData.Description}</Text>
        </View>
        <View style={[styles.section]}>
          <Text style={styles.header}>Order Information</Text>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Order:</Text>
            <Text>#{props.orderData.ordersId}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>HOA:</Text>
            <Text>{props.orderData.hoa.name}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Property Address:</Text>
            <Text>{props.orderData.address}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Status:</Text>
            <Text>{props.docStatusData.Description}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Transfer Date:</Text>
            <Text>{props.orderData.transferDt}</Text>
          </View>
        </View>

        <View style={[styles.section]}>
          <Text style={styles.header}>Document Buyer</Text>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Name:</Text>
            <Text>{props.userData.FirstName} {props.userData.LastName}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Email:</Text>
            <Text>{props.userData.Email}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Phone:</Text>
            <Text>{formatPhoneNumber(props.userData.Phone)}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>Address:</Text>
            <Text>{props.userData.Address}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween]}>
            <Text>City:</Text>
            <Text>{props.userData.City}</Text>
          </View>
        </View>

        <View style={[styles.section]}>
          <Text style={styles.header}>Fees and Assessments</Text>
          <View style={[styles.row, styles.spaceBetween, { padding: "2pt" }]}>
            <Text>Unpaid Assessments {props.values.unpaidAssessmentsTypes.length > 0 ? `(${props.values.unpaidAssessmentsTypes})` : ``}</Text>
            <Text>{dollarFormatter.format(props.values.unpaidAssessmentsAmount)}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween, styles.plum]}>
            <Text>Association Late Fee Due</Text>
            <Text>{dollarFormatter.format(props.values.associationLateFeeDue)}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween, { padding: "2pt" }]}>
            <Text>Management Late Fee Due</Text>
            <Text>{dollarFormatter.format(props.values.managementLateFeeDue)}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween, styles.plum]}>
            <Text>Other Fees</Text>
            <Text>{dollarFormatter.format(props.values.otherFees)}</Text>
          </View>
          <View style={[styles.row, styles.spaceBetween, { padding: "2pt" }, { marginTop: "10pt" }]} >
            <Text>Total</Text>
            <Text>{dollarFormatter.format(dueSum)}</Text>
          </View>
          <View style={[styles.row, { justifyContent: "flex-end", padding: "2pt", marginTop: "10pt" }]} >
            <Text style={{ fontStyle: "italic" }}>{`The association account for the property is ${props.values.inCollections === 1 ? "" : "not "}in collections.`}</Text>
          </View>
        </View>

        <View style={[styles.section, styles.extraNotes]}>
          <Text style={styles.header}>Additional Notes</Text>
          <View>
            <Text>
              {props.values.additionalText}
            </Text>
          </View>
        </View>

      </Page>
    </Document>
  )



};

export default OrderPdfTemplate;