import axios, { AxiosResponse } from 'axios';
import userId from '../../userId';
import { IPageData, IPutURL } from "./submitDataInterfaces";

import postExtraFiles from "./postExtraFiles";

import dayjs from 'dayjs';


const submitOrder = async (pageData: IPageData, extraFiles: Array<File>, orderPDF: File): Promise<void> => {

    //orderItemId from parent page
    const oidId: number = pageData.orderData.oidId;

    //get the presigned PUT URL
    await axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/awss3/${oidId}`).then(putURLResponse => {

        const AWS3GetURL: string = putURLResponse.data.result.getURL;
        const AWS3PutURL: string = putURLResponse.data.result.putURL;

        //This posts the extra files. more work needs to be done on this before it's ready.
        // if (extraFiles.length > 0) {
        //     //post all non-order document files
        //     postExtraFiles(extraFiles, oidId);
        // }

        //next, upload the Order Document PDF to the PutUR:
        return axios.put(AWS3PutURL, orderPDF).then((pdfPutResponse) => {

            let date = dayjs().format('YYYY-MM-DD HH:mm:ss')

            //update the OrderItemDocument record, and you're done!
            const orderItemDocumentUpdate = {
                "item": {
                    "CompletedDate": date,
                    "DocumentStatusId": 3,
                    "CompletedBy": userId,
                    "S3RelativePath": AWS3GetURL.replace("https://s3.us-west-2.amazonaws.com/pdf-dev.getdocsnow.com/", "") // Also need to replac everything after .pdf 
                }
            }

            return axios.put(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/orderItemDocument/${oidId}`,
                orderItemDocumentUpdate, { headers: { "Content-Type": "application/json" } }
            ).then(response => {

            }).catch((error: any) => {
                console.log(`ERROR: `, error);
            });

        }).catch((error: any) => {
            console.log(`ERROR: `, error);
        });

    }).catch((error: any) => {
        console.log(`ERROR: `, error);

    }).catch((error: any) => {
        console.log(`ERROR: `, error);
    })

}

export default submitOrder;