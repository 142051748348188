import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useHistory } from "react-router-dom";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import userId from '../../userId';
import { IOrder, IOrdersData } from "./pendingOrdersInterfaces"
import generateDocTypeMap from "../../utils/generateDocTypeMap";
import formatPhoneNumber from '../../utils/formatPhoneNumber';

import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

const PendingOrdersPage = () => {

    const history = useHistory();
    //for getting around.

    const [ordersData, setOrdersData] = useState<IOrdersData>({ ordersArr: [], docTypeMap: undefined, orderDocLinks: [], loading: true });

    //retrieve client's orders from API
    const getPendingOrders = async () => {


        return axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/pendingOrders/${userId}`)
            .then(async function (response: AxiosResponse<any>) {
                // handle success

                const docTypeMap = await generateDocTypeMap(response.data.result);

                const orderarr: Array<IOrder> = response.data.result.sort(
                    (order1: IOrder, order2: IOrder) => {
                        return order1.oidId - order2.oidId
                    }
                );


                setOrdersData({ ordersArr: orderarr, docTypeMap: docTypeMap, orderDocLinks: [], loading: false });

            })
            .catch(function (error: any) {
                // handle error
                console.log(error);
            }
            );
    };

    //get pending orders when page renders
    useEffect(() => {
        getPendingOrders();
    }, []);

    //show orders if retrieved by API
    if (!ordersData.loading) {

        return (
            <div style={{
                marginTop: "2em"
            }}>
                <Row>
                    <Col className="justify-content-center">
                        <h1 className="page-title" >Pending Orders</h1>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <div>
                            <Table striped responsive="xl" hover>
                                <thead>
                                    <tr>
                                        <th>Order</th>
                                        <th>Doc Type</th>
                                        <th>HOA</th>
                                        <th>Address</th>
                                        <th>Buyer</th>
                                        <th>Files/Action</th>
                                        <th>
                                            <tr>
                                                <td className="purchased PFT">Purchased</td>
                                                <td className="fulfill PFT">Fulfilled By</td>
                                                <td className="transfer PFT">Transfer</td>
                                            </tr>
                                        </th>
                                    </tr>
                                </thead>
                                {

                                    ordersData.ordersArr.length > 0 ?

                                        ordersData.ordersArr.map((order: IOrder) => {

                                            return (
                                                <tr key={order.oidId}>
                                                    <td>{order.ordersId}</td>

                                                    {/* undefined */}
                                                    <td>{ordersData.docTypeMap.get(order.docTypeId)}</td>
                                                    <td>{order.hoa.name}</td>
                                                    <td>{order.address}</td>
                                                    <td>
                                                        <tr>
                                                            {order.buyer.email}
                                                        </tr>
                                                        <tr>
                                                            {formatPhoneNumber(order.buyer.phone)}
                                                        </tr>
                                                    </td>
                                                    <td><Button size="sm" variant="info" onClick={() => history.push(`/submit-data/${order.ordersId}`)}>Submit Order Doc</Button></td>
                                                    <td>
                                                        <tr><td style={{ fontSize: "13px" }} className="purchased PFT">{new Date(order.purchaseDt).toLocaleString('en-US')} GMT</td></tr>
                                                        <tr><td style={{ fontSize: "13px" }} className="fulfill PFT">{new Date(order.dueDt).toLocaleString('en-US')} GMT</td></tr>
                                                        <tr><td style={{ fontSize: "13px" }} className="transfer PFT">{new Date(order.transferDt).toLocaleString('en-US')} GMT</td></tr>
                                                    </td>
                                                </tr>
                                            )

                                        })

                                        :

                                        <tr>
                                            <td colSpan={7}>
                                                There are no pending orders at this time.
                                    </td>
                                        </tr>
                                }
                            </Table>
                        </div>
                    </Col>

                </Row>

            </div>
        )
    }
    else {
        return (
            <LoadingSpinner />
        )
    }

};

export default PendingOrdersPage;