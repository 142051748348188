import axios, { AxiosResponse } from 'axios';
import { IOrder } from "../PendingOrdersPage/pendingOrdersInterfaces";

const getOrderDocLinks = async (orders: Array<IOrder>) => {

    // get URLs for each order doc pdf


    let linkArr: Array<string> = await Promise.all(orders.map((order: IOrder) => axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/awss3Download/${order.oidId}`)
        .then(r => r.data.result.getURL)))

    return linkArr;



}

export default getOrderDocLinks;