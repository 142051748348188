import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useHistory } from "react-router-dom";

import { createBrowserHistory } from "history";

import { useLocation } from "react-router-dom";
import './App.css';

import PendingOrdersPage from './components/PendingOrdersPage/PendingOrdersPage';
import CompletedOrdersPage from './components/CompletedOrdersPage/CompletedOrdersPage';
import SubmitDataPage from './components/SubmitDataPage/SubmitDataPage';
import ScrollToTop from './utils/ScrollToTop';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {  Nav, Navbar, NavbarBrand } from 'react-bootstrap';

class AppFooter extends Component{
  render() {
      return(
          <div className="page-footer dark">  
              <Navbar bg="light" color="light">
                  <Container>
                      <NavbarBrand>GetDocsNow</NavbarBrand>
                  </Container>
              </Navbar>
          </div>
      )
  }
}

function App() {

  let history = useHistory();
  //for getting around.

  return (
    <div>
      <Router>
        <Navbar bg="light" className="py-4" sticky="top">
        {/* <Container > */}
            <Navbar.Brand ><img
            src="https://www.getdocsnow.com/images/logo.png"
            // width="30"
            height="60"
            className="d-inline-block align-top"
            alt="GetDocsNow"
          />
        </Navbar.Brand>

        <Nav.Item>
              <Nav.Link href="/" >Pending</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/completed-orders">Completed</Nav.Link>
            </Nav.Item>
            
          {/* </Container> */}
          </Navbar>
              
        <Container className="container-color" fluid >
            <ScrollToTop />
            

            <Row>
              <Col xs={1}></Col>
              <Col xs={10}>           
                <Switch>
                  <Route path="/"  exact component={PendingOrdersPage} />
                  <Route path="/completed-orders"  exact component={CompletedOrdersPage} />
                  <Route path="/submit-data/:id"  component={SubmitDataPage} />
                </Switch>
              </Col>
              <Col xs={1}></Col> 
              </Row>
              <Row></Row>
        </Container>
      </Router>
      <AppFooter/>
    </div>
  );
}

export default App;
