import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { BrowserRouter as Router, Switch, Route, Link, useParams, RouteComponentProps, useHistory } from "react-router-dom";
import { Formik, validateYupSchema } from 'formik';
// import download from "download";

import formatPhoneNumber from '../../utils/formatPhoneNumber';
import { Order, IPageData, IFormData } from "./submitDataInterfaces";
import userId from '../../userId';
import OrderSchema from "./orderSchema";
import OrderPdfTemplate from "./OrderPdfTemplate";
import submitOrder from './submitOrder';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import Jumbotron from 'react-bootstrap/Jumbotron'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


type TParams = { id: string };

const SubmitDataPage = ({ match }: RouteComponentProps<TParams>) => {

    //turn numbers to currency
    const dollarFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleBlur = (e: any) => {
        let formattedNum = dollarFormatter.format(e.target.value);
        return formattedNum
    }

    let history = useHistory();
    //for getting around.

    //data from API
    const [pageData, setPageData] = useState<IPageData | undefined>(undefined);

    //show pdf preview modal
    const [showPreview, setShowPreview] = useState<boolean>(false);

    //add file to upload with order
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [extraFiles, setextraFiles] = useState<Array<File>>([]);

    //formik form values
    const initialValues: IFormData = {

        inCollections: 1,
        unpaidAssessmentsAmount: 0,
        unpaidAssessmentsTypes: '',
        associationLateFeeDue: 0,
        managementLateFeeDue: 0,
        otherFees: 0,
        additionalText: ''
    };

    //get information for API call from url
    const orderId: string = match.params.id;

    //api call
    const getDoc = async (id: string) => {

        let foundOrder: Order;

        //get order data
        return axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/pendingOrders/${userId}`).then(function async(response: AxiosResponse<any>) {

            foundOrder = response.data.result.find((order: Order) => order.ordersId === Number(orderId));

            //get more details on order from API
            const getMoreInfo = async (userId: number, docTypeId: number, docStatusId: number) => {
                const [userResponse, docTypeResponse, docStatusResponse] = await Promise.all([
                    axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/user?UserId=${userId}`),
                    axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/documenttype?DocumentTypeId=${docTypeId}`),
                    axios.get(`https://hwgaf1pax8.execute-api.us-west-2.amazonaws.com/dev/documentstatus?DocumentStatusId=${docStatusId}`)
                ]);


                // set page to be seen with order data, user data, and doctype data;
                setPageData({
                    orderData: foundOrder,
                    docStatusData: docStatusResponse.data.result[0],
                    docTypeData: docTypeResponse.data.result[0],
                    userData: userResponse.data.result[0],
                    loading: false
                });

            }

            getMoreInfo(foundOrder.buyer.userId, foundOrder.docTypeId, foundOrder.docStatusId);

        }).catch((error: any) => {
            // handle error
            console.log(error);
        });

    };

    useEffect(() => {

        getDoc(orderId);

    }, [])

    //sets selected uploaded file
    const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.files != null) {

            const file: File = event.target.files[0];
            setSelectedFile(file)
        }
    }
    //add uploaded file.
    const addFile = () => {

        if (selectedFile !== undefined) {

            //check if file with same name has already been uploaded
            const alreadyUploaded = () => {

                extraFiles.find((file: File) => {
                    return file.name === selectedFile.name;
                })

            }

            if (alreadyUploaded() === undefined) {
                setextraFiles([...extraFiles, selectedFile]);
                setSelectedFile(undefined);
            }
            else {
                alert("This file has already been uploaded")
            }

        }

    }

    //delete uploaded file.
    const deleteFile = (idx: number) => {
        const remainingFiles: Array<File> = extraFiles.filter((file: File) => { return file.name !== extraFiles[idx].name });
        setextraFiles(remainingFiles);

    }

    //wait until all API calls are done to display page
    if (pageData !== undefined && !pageData.loading) {

        return (
            <div style={{ marginTop: "2em" }}>
                <h2 className="doc-description">Submit {pageData.docTypeData.Description}</h2>
                {/* <div><p>{`wow, ${orderId}`}</p></div> */}
                <Row>
                    <Col>
                        <Jumbotron>
                            <h2 className="order">Order #{pageData.orderData.ordersId}</h2>
                            <p>HOA: {pageData.orderData.hoa.name}</p>
                            <p>Property Address: {pageData.orderData.address}</p>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} >

                        <div className="panel-grey">
                            <b>Current Status</b>
                            <p>{pageData.docStatusData.Description}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="panel-grey">
                            <b>Document Buyer</b>
                            <p>{pageData.userData.FirstName} {pageData.userData.LastName} {formatPhoneNumber(pageData.userData.Phone)}</p>
                            <p style={{ marginTop: "-20px" }}>{pageData.userData.Email}</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4} >

                        <div className="panel-grey">
                            <b>Estimated Transfer</b>
                            <p>{pageData.orderData.transferDt}</p>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col>

                    </Col>
                </Row>

                <Row>
                    <Col>
                        {/* form starts here. */}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={OrderSchema}
                            onSubmit={async (values, actions) => {


                                //convert pdf
                                let props = {
                                    "docTypeData": pageData.docTypeData,
                                    "orderData": pageData.orderData,
                                    "docStatusData": pageData.docStatusData,
                                    "userData": pageData.userData,
                                    "values": values
                                };

                                const blob = await pdf(OrderPdfTemplate(props)).toBlob();
                                let orderPDF = new File([blob], `${pageData.docTypeData.Description}.pdf`, { "type": 'application/pdf', "lastModified": Date.now() });

                                await submitOrder(pageData, extraFiles, orderPDF);

                                //download order document pdf programatically
                                const pdfURL = URL.createObjectURL(orderPDF);
                                let link = document.createElement("a");
                                link.href = pdfURL;
                                link.download = `submitted ${pageData.docTypeData.Description}.pdf`;
                                link.click();
                                actions.setSubmitting(false);
                                history.push(`/completed-orders`)

                            }}
                        >
                            {/* form is a callback that passes formik values down as props */}
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors
                            }) => (
                                <Form onSubmit={handleSubmit}>

                                    <Form.Group >
                                        <Form.Label>Is the association account for the Property in collections?</Form.Label>
                                        <Form.Control as="select" name="inCollections" value={values.inCollections} onChange={handleChange}>
                                            <option value={1}>Yes, it IS in collections</option>
                                            <option value={0}>No, it is NOT in collections</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Amount of Unpaid Assessments</Form.Label>
                                        <Form.Control type="number" min="0" placeholder="$0.00" name="unpaidAssessmentsAmount" value={values.unpaidAssessmentsAmount} onChange={handleChange} />
                                        {errors.unpaidAssessmentsAmount && touched.unpaidAssessmentsAmount ? (<div style={{ color: "red" }}>{errors.unpaidAssessmentsAmount}</div>) : null}
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Types of Unpaid Assessments</Form.Label>
                                        <Form.Control type="text" placeholder="Types of Unpaid Assessments" name="unpaidAssessmentsTypes" value={values.unpaidAssessmentsTypes} onChange={handleChange} />
                                        <Form.Text className="text-muted">
                                            For example, special assessments, common expense assessments, reserve assessments, etc.
                                    </Form.Text>

                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Association Late Fee Amount Due</Form.Label>
                                        <Form.Control type="number" min="0" placeholder="$0.00" name="associationLateFeeDue" value={values.associationLateFeeDue} onChange={handleChange} />
                                        {errors.associationLateFeeDue && touched.associationLateFeeDue ? <div style={{ color: "red" }}>{errors.associationLateFeeDue}</div> : null}

                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Management Late Fee Amount Due</Form.Label>
                                        <Form.Control type="number" min="0" placeholder="$0.00" name="managementLateFeeDue" value={values.managementLateFeeDue} onChange={handleChange} />
                                        {errors.managementLateFeeDue && touched.managementLateFeeDue ? <div style={{ color: "red" }}>{errors.managementLateFeeDue}</div> : null}

                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Other Fees</Form.Label>
                                        <Form.Control type="number" min="0" placeholder="$0.00" name="otherFees" value={values.otherFees} onChange={handleChange} />
                                        {errors.otherFees && touched.otherFees ? <div style={{ color: "red" }}>{errors.otherFees}</div> : null}

                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label>Additional Text / Notes</Form.Label>
                                        <Form.Control as="textarea" rows={3} name="additionalText" value={values.additionalText} onChange={handleChange} />
                                        <Form.Text className="text-muted">
                                            {values.additionalText.length > 1000 ?
                                                `You've typed ${values.additionalText.length - 1000} over the limit.`
                                                : `${1000 - values.additionalText.length} characters remaining.`}
                                        </Form.Text>
                                        {errors.additionalText && touched.additionalText ? <div style={{ color: "red" }}>{errors.additionalText}</div> : null}
                                    </Form.Group>
                                    <Form.Group >
                                        {/* upload files here */}
                                        <Form.Label>Additional Files</Form.Label>
                                        <Row>
                                            <Col>
                                                <Form.File id="file-input" disabled={true} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectedFile(event)} />
                                            </Col>
                                            <Col>
                                                <Button variant="success" disabled={true} onClick={() => addFile()}>Upload</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ListGroup variant="flush">
                                                    {/* display all uploaded files */}
                                                    {
                                                        extraFiles.length > 0 ?

                                                            extraFiles.map((file: File, idx) => {
                                                                return (
                                                                    <ListGroup.Item key={file.name} className="uploaded-file">
                                                                        {file.name}<Button className="extra-file-delete" onClick={() => deleteFile(idx)} variant="danger">Delete</Button>
                                                                    </ListGroup.Item>
                                                                )
                                                            }
                                                            )
                                                            :
                                                            <div></div>
                                                    }

                                                </ListGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Row style={{ marginTop: "5em", marginBottom: "1em", borderTop: "1px solid purple", paddingTop: "1em" }}>
                                        <Col className="d-flex justify-content-center">
                                            <Button className="justify-content-center" variant="secondary" onClick={() => history.push('')}>Cancel</Button>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <Button className="justify-content-center" variant="info" onClick={() => setShowPreview(true)}>Preview</Button>
                                        </Col>
                                        <Col className="d-flex justify-content-center">
                                            <Button className="justify-content-center" variant="primary" type="submit">Submit</Button>
                                        </Col>
                                    </Row>
                                    {/* preview modal */}
                                    <Modal size="lg" show={showPreview} onHide={() => setShowPreview(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Order Preview</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <PDFViewer width="100%" height="700px" >
                                                <OrderPdfTemplate
                                                    docTypeData={pageData.docTypeData}
                                                    orderData={pageData.orderData}
                                                    docStatusData={pageData.docStatusData}
                                                    userData={pageData.userData}
                                                    values={values}
                                                />
                                            </PDFViewer>
                                        </Modal.Body>
                                        <Modal.Footer>

                                        </Modal.Footer>
                                    </Modal>

                                </Form>
                            )}

                        </Formik>
                    </Col>
                </Row>



            </div>
        )
    }
    else {
        // A gorgeous loading spinner.
        return (<LoadingSpinner />)
    }
}

export default SubmitDataPage;